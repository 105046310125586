import { Injectable } from '@angular/core';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// models
import { Template } from 'app/shared/types/template.types';
import { FirestoreFilter } from 'app/core/firebase/FirestoreFilter';
import { SignRequest } from 'app/shared/types/sign-request';
import { Signer } from 'app/core/signer/signer.model';
import { DocumentReference } from '@angular/fire/firestore';

// enum
import { DocumentStatus } from 'app/shared/enums/documentStatus.enum';
import { DocumentAction } from 'app/shared/enums/documentAction.enum';

// services
import { AccountService } from 'app/core/account/account.service';
import { FirestoreService } from 'app/core/firebase/firestore.service';
import { StorageService } from 'app/core/firebase/storage.service';
import { UserService } from 'app/core/user/user.service';
import { ActivityService } from 'app/core/activity/activity.service';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class CreateRequestService{
	private _accountRef: DocumentReference;
	private _userRef: DocumentReference;
	private _templates: BehaviorSubject<Template[]> = new BehaviorSubject<
		Template[]
	>(null);
	private _onFilesSelected: BehaviorSubject<any> = new BehaviorSubject(null);
	constructor(
		private _accountService: AccountService,
		private _firestoreService: FirestoreService,
		private _storageService: StorageService,
		private _userService: UserService,
		private _activityService: ActivityService,
		private _authService: AuthService
	) {
		this._initTemplates();

		this._accountService.accountRef$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((accountRef) => {
				this._accountRef = accountRef;
			});

		this._userService.userRef$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((userRef) => {
				this._userRef = userRef;
			});
	}

	// ---------------------------------------------------------------------------
	// @ LIFECYCLE HOOKS
	// ---------------------------------------------------------------------------


	// ---------------------------------------------------------------------------
	// @ PRIVATE METHODS
	// ---------------------------------------------------------------------------
	private _initTemplates() {
		this._accountService.accountRef$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((accountRef) => {
				const filter: FirestoreFilter = {
					column: 'account',
					operator: '==',
					value: accountRef,
				};

				this._firestoreService
					.getCollectionQueryChanges('templates', filter, 'title', 'asc')
					.pipe(takeUntil(this._authService._unsubscribeAll))
					.subscribe((templates) => {
						this._templates.next(templates as Template[]);
					});
			});
	}

	private _createSignRequest(signRequest: SignRequest, id: string) {
		return this._firestoreService.setDocument('signRequests', signRequest, id);
	}

	// ---------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// ---------------------------------------------------------------------------

	createRequestFromTemplate(
		template: Template,
		signReqeust: Partial<SignRequest>,
		signers: { [key: string]: Signer }
	): Promise<string> {
		let signRequestID = this._firestoreService.getNewID();

		// set signer options
		for (let signer in template.signers)
			signers[signer].options = template.signers[signer].options;

		let signRequest: SignRequest = {
			title: signReqeust.title,
			status: DocumentStatus.created,
			createdAt: new Date(),
			filePath: '',
			fileName: '',
			user: this._userRef,
			priority: signReqeust.priority,
			signers: signers,
			placeholders: template.placeholders,
			account: this._accountRef,
			syncSigning: signReqeust.syncSigning,
		};

		return new Promise((resolve, reject) => {
			this._storageService
				.getFile(template.filePath)
				.then((file) => {
					this._storageService
						.storeFile(
							`signRequests/${this._accountRef.id}/${this._userService.userID}/${signRequestID}/${template.fileName}`,
							file
						)
						.then((uploadTask) => {
							signRequest.filePath = uploadTask.ref.fullPath;
							signRequest.fileName = uploadTask.ref.name;
							this._createSignRequest(signRequest, signRequestID)
								.then((docRef) => {
									this._activityService.createActivity(docRef, {
										date: new Date(),
										user: this._userService.email,
										action: DocumentAction.created,
									});
									resolve(signRequestID);
								})
								.catch((error) => reject(error));
						})
						.catch((error) => {
							reject(error);
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	createRequestFromFile(
		signReqeust: Partial<SignRequest>,
		signers: { [key: string]: Signer },
		file: File
	): Promise<string> {
		let signRequestID = this._firestoreService.getNewID();

		let signRequest: SignRequest = {
			title: signReqeust.title,
			status: DocumentStatus.created,
			createdAt: new Date(),
			filePath: '',
			fileName: '',
			user: this._userRef,
			priority: signReqeust.priority,
			signers: signers,
			placeholders: {},
			account: this._accountRef,
			syncSigning: signReqeust.syncSigning,
		};

		return new Promise((resolve, reject) => {
			this._storageService
				.storeFile(
					`signRequests/${this._accountRef.id}/${this._userService.userID}/${signRequestID}/${file.name}`,
					file
				)
				.then((uploadTask) => {
					signRequest.filePath = uploadTask.ref.fullPath;
					signRequest.fileName = uploadTask.ref.name;
					this._createSignRequest(signRequest, signRequestID)
						.then((docRef) => {
							this._activityService.createActivity(docRef, {
								date: new Date(),
								user: this._userService.email,
								action: DocumentAction.created,
							});
							resolve(signRequestID);
						})
						.catch((error) => reject(error));
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	fileSelectedForNewRequest(event: any) {
		this._onFilesSelected.next(event);
	}
	// ---------------------------------------------------------------------------
	// @ ACCESSORS
	// ---------------------------------------------------------------------------
	get templates$(): Observable<Template[]> {
		return this._templates.asObservable();
	}

	get onFilesSelected$(): Observable<any> {
		return this._onFilesSelected.asObservable();
	}
}
