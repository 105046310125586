import { Injectable } from '@angular/core';

// rxjs
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

// services
import { FirestoreService } from 'app/core/firebase/firestore.service';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';

// models
import { FirestoreFilter } from 'app/core/firebase/FirestoreFilter';
import { StorageService } from 'app/core/firebase/storage.service';
import { Template } from 'app/shared/types/template.types';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class TemplateManagerService {
	randomString: string;

	// private data vars
	private _templates: BehaviorSubject<Template[]> = new BehaviorSubject<
		Template[]
	>(null);
	private _template: BehaviorSubject<Template> = new BehaviorSubject<Template>(
		null
	);

	constructor(
		private _firestoreService: FirestoreService,
		private _userService: UserService,
		private _storageService: StorageService,
		private _authService: AuthService,
		private _router: Router
	) {
		this._initTemplates(false, false);
	}

	// ---------------------------------------------------------------------------
	// @ Private Functions
	// ---------------------------------------------------------------------------

	private _initTemplates(pOrdercolumn, pOrderDirection): Promise<Template[]> {
		return new Promise((resolve, reject) => {
			if (this._userService.account) {
				const filter: FirestoreFilter = {
					column: 'account',
					operator: '==',
					value: this._userService.account,
				};

				this._firestoreService
					.getCollectionQueryChanges(
						'templates',
						filter,
						pOrdercolumn,
						pOrderDirection
					)
					.pipe(takeUntil(this._authService._unsubscribeAll))
					.subscribe(
						(templates) => {
							this._templates.next(templates as Template[]);
							resolve(templates as Template[]);
						},
						(error) => {
							this._router.navigate(['error']);
						}
					);
			}
		});
	}

	// ---------------------------------------------------------------------------
	// @ Variables
	// ---------------------------------------------------------------------------
	get templates$(): Observable<Template[]> {
		return this._templates.asObservable();
	}

	get template$(): Observable<Template> {
		return this._template.asObservable();
	}

	// ---------------------------------------------------------------------------
	// @ Public functions
	// ---------------------------------------------------------------------------

	/**
	 * Get item by id
	 */
	getItemById(id: string): Observable<Template> {
		return this._templates.pipe(
			take(1),
			map((templates) => {
				// Find within the folders and files
				const template = [...templates].find((value) => value.id === id) || null;
				// Update the item
				this._template.next(template);
				// Return the item
				return template;
			}),
			switchMap((item) => {
				if (!item) {
					return throwError('Could not found the template with id of ' + id + '!');
				}

				return of(item);
			}),
			takeUntil(this._authService._unsubscribeAll)
		);
	}

	getItems(pOrdercolumn?, pOrderDirection?) {
		return this._initTemplates(pOrdercolumn, pOrderDirection);
	}

	updateTemplate(documentId: string, template: Template): Promise<void> {
		this._template.value.remarks = template.remarks;
		return this._firestoreService.updateDocument(
			'templates',
			template,
			documentId
		);
	}

	deleteTemplate(template: Template, document: File): Promise<any[]> {
		let deletions = [];

		if (template.fileName) {
			// delete folder with encoded pdf
			deletions.push(
				this._storageService.deleteFile(
					`templates/${template.account.id}/${template.fileName}`
				)
			);
		}
		// delete firestore entry
		deletions.push(
			this._firestoreService.deleteDocument('templates', template.id)
		);

		return Promise.all(deletions);
	}
}
