export enum DocumentAction {
    created = "CREATED",
    activated = "ACTIVATED",
    messageSent = "MESSAGESENT",
    accessed = "ACCESSED",
    signatureReceived = "SIGNATURERECEIVED",
    merged = "MERGED",
    download = "DOWNLOAD",
    mailSent = "MAILSENT",
    rejected = "REJECTED",
}
