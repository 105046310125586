import { Injectable, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

//autogramm.io - Services
import { UserService } from 'app/core/user/user.service';

//autogramm.io - models
import { Activity } from 'app/core/activity/activity.model';
import { User } from 'app/core/user/user.model';

//autogramm.io - enums
import { DocumentReference } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';


@Injectable({
	providedIn: 'root',
})
export class ActivityService implements OnInit {
	user: User;

	/**
	 *constructor
	 *@param {FirestoreService} _firestoreService
	 */
	constructor(
		private _userService: UserService,
		private _authService: AuthService
	) {}
	ngOnInit(): void {
		this._userService.user$.pipe(takeUntil(this._authService._unsubscribeAll)).subscribe((usr) => {
			this.user = usr;
		});
	}

	createActivity(parent: DocumentReference, pAction: Activity) {
		return parent.collection('activities').add(pAction);
	}
}
